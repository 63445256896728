import React from "react";
import "../assets/styles/About.css";

const About = () => {
  return (
    <div className="about-container">
      <h1>About FileFusion.fun</h1>
      <p>
        Welcome to FileFusion.fun! We provide various tools for file conversion
        to make your tasks easier.
      </p>
      <p>
        Our team is dedicated to providing the best user experience and
        continuously improving our services.
      </p>
    </div>
  );
};

export default About;
