import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <p>
        At FileFusion.fun, the privacy of our visitors is of extreme importance
        to us. This privacy policy document outlines the types of personal
        information is received and collected by FileFusion.fun and how it is
        used.
      </p>
      <h3>Log Files</h3>
      <p>
        Like many other Web sites, FileFusion.fun makes use of log files. The
        information inside the log files includes internet protocol ( IP )
        addresses, type of browser, Internet Service Provider ( ISP ), date/time
        stamp, referring/exit pages, and number of clicks to analyze trends,
        administer the site, track user’s movement around the site, and gather
        demographic information. IP addresses, and other such information are
        not linked to any information that is personally identifiable.
      </p>
      <h3>Cookies and Web Beacons</h3>
      <p>FileFusion.fun does not use cookies.</p>
      <h3>Contact Information</h3>
      <p>
        If you require any more information or have any questions about our
        privacy policy, please feel free to contact us by email at
        donkhandj99@gmail.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
