import React, { useEffect, useRef } from "react";
import "../assets/styles/Cursor.css";

const Cursor = () => {
  const cursorRef = useRef(null);
  const trailRef = useRef(null);

  useEffect(() => {
    const moveCursor = (event) => {
      const { clientX: x, clientY: y } = event;
      const scrollX = window.pageXOffset;
      const scrollY = window.pageYOffset;
      cursorRef.current.style.left = `${x + scrollX}px`;
      cursorRef.current.style.top = `${y + scrollY}px`;
      trailRef.current.style.transform = `translate(${x - 10 + scrollX}px, ${
        y - 10 + scrollY
      }px)`;
      createParticles(x + scrollX, y + scrollY);
    };

    const createParticles = (x, y) => {
      const particle = document.createElement("div");
      particle.className = "particle";
      particle.style.left = `${x}px`;
      particle.style.top = `${y}px`;
      document.body.appendChild(particle);
      setTimeout(() => {
        particle.remove();
      }, 1000);
    };

    window.addEventListener("mousemove", moveCursor);

    return () => {
      window.removeEventListener("mousemove", moveCursor);
    };
  }, []);

  return (
    <>
      <div className="custom-cursor" ref={cursorRef}></div>
      <div className="cursor-trail" ref={trailRef}></div>
    </>
  );
};

export default Cursor;
