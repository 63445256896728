import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/styles/Header.css";

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo">
          <Link to="/">FileFusion.fun</Link>
        </div>
        <nav className={`nav`}>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li className="dropdown-container">
              <a href="#">Services</a>
              <div className="dropdown">
                <Link to="/pdf-to-image">PDF to Image</Link>
                <Link to="/pdf-to-word">PDF to Word</Link>
                {/* <Link to="/text-to-handwriting">Text to Handwriting</Link>{" "} */}
              </div>
            </li>
            {/* <li>
              <Link to="/testimonials">Testimonials</Link>
            </li> */}
            <li className="dropdown-container">
              <a href="#">Legal</a>
              <div className="dropdown">
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-of-service">Terms of Service</Link>
              </div>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            {/* <li>
              <Link to="/about">About</Link>
            </li> */}
            {/* <li>
              <Link to="/blog">Blog</Link>
            </li> */}
          </ul>
        </nav>
        <div className="hamburger" onClick={toggleSidebar}>
          ☰
        </div>
      </div>
      <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
        <nav className="sidebar-nav">
          <ul>
            <li>
              <Link to="/" onClick={toggleSidebar}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/faq" onClick={toggleSidebar}>
                FAQ
              </Link>
            </li>
            <li>
              <Link to="/pdf-to-image" onClick={toggleSidebar}>
                PDF to Image
              </Link>
            </li>
            <li>
              <Link to="/pdf-to-word" onClick={toggleSidebar}>
                PDF to Word
              </Link>
            </li>
            {/* <li>
              <Link to="/text-to-handwriting">Text to Handwriting</Link>{" "}
            </li> */}
            {/* <li>
              <Link to="/testimonials" onClick={toggleSidebar}>Testimonials</Link>
            </li> */}
            <li>
              <Link to="/contact" onClick={toggleSidebar}>
                Contact
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={toggleSidebar}>
                About
              </Link>
            </li>
            {/* <li>
              <Link to="/blog" onClick={toggleSidebar}>Blog</Link>
            </li> */}
            <li>
              <Link to="/privacy-policy" onClick={toggleSidebar}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/terms-of-service" onClick={toggleSidebar}>
                Terms of Service
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
