import React from "react";
import { Link } from "react-router-dom";
import "../assets/styles/Home.css";

const Home = () => {
  return (
    <div className="home-container">
      <h1>Welcome to FileFusion.fun!</h1>
      <p>
        Where the mystical forces of technology meet your everyday document
        needs, sprinkled with a touch of whimsy!
      </p>
      <div className="services">
        <h2>Our Whimsical Services</h2>
        <ul>
          <li>
            <strong>PDF to Image:</strong> Watch your PDFs undergo a magical
            transformation into a vibrant gallery of images! Because who says
            documents can't be fabulous works of art?
          </li>
          <li>
            <strong>PDF to Word:</strong> Set your words free! Our wizardry
            turns PDFs into editable Word documents, because sometimes text just
            wants to roam wild and free!
          </li>
          <li>
            <strong>Text to Handwriting:</strong> Say goodbye to impersonal
            messages and hello to handwritten charm! Transform your typed text
            into delightful handwritten notes that add a personal touch to your
            digital communications. Perfect for sending love letters to your
            laptop!
          </li>
          <li>
            <strong>Text to Logo Generation:</strong> Get ready to unleash your
            brand's personality with our upcoming "Text to Logo" service! Coming
            soon to add a sprinkle of magic to your branding endeavors. Stay
            tuned for the grand reveal of this enchanting feature!
          </li>
        </ul>
      </div>
      <div className="call-to-action">
        <p>Ready to experience the magic?</p>
        <Link to="/pdf-to-image" className="btn btn-primary">
          Convert PDF to Image
        </Link>
      </div>
    </div>
  );
};

export default Home;
