import React from "react";
import PropTypes from "prop-types";
import "../assets/styles/Loader.css";

const Loader = ({ visible }) => {
  if (!visible) return null;

  return (
    <div className="loader-container">
      <div className="loader"></div>
      <p className="loader-text">Loading...</p>
    </div>
  );
};

Loader.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default Loader;
