import React from "react";
import "../assets/styles/TermsOfService.css";

const TermsOfService = () => {
  return (
    <div>
      <h2>Terms and Conditions</h2>
      <p>
        By accessing this web site, you are agreeing to be bound by these web
        site Terms and Conditions of Use, all applicable laws and regulations,
        and agree that you are responsible for compliance with any applicable
        local laws. If you do not agree with any of these terms, you are
        prohibited from using or accessing this site. The materials contained in
        this web site are protected by applicable copyright and trade mark law.
      </p>
      <h3>Use License</h3>
      <p>
        Permission is granted to temporarily download one copy of the materials
        (information or software) on FileFusion.fun's web site for personal,
        non-commercial transitory viewing only. This is the grant of a license,
        not a transfer of title, and under this license you may not:
        <ul>
          <li>modify or copy the materials;</li>
          <li>
            use the materials for any commercial purpose, or for any public
            display (commercial or non-commercial);
          </li>
          <li>
            attempt to decompile or reverse engineer any software contained on
            FileFusion.fun's web site;
          </li>
          <li>
            remove any copyright or other proprietary notations from the
            materials; or
          </li>
          <li>
            transfer the materials to another person or "mirror" the materials
            on any other server.
          </li>
        </ul>
        This license shall automatically terminate if you violate any of these
        restrictions and may be terminated by FileFusion.fun at any time. Upon
        terminating your viewing of these materials or upon the termination of
        this license, you must destroy any downloaded materials in your
        possession whether in electronic or printed format.
      </p>
      <h3>Disclaimer</h3>
      <p>
        The materials on FileFusion.fun's web site are provided "as is".
        FileFusion.fun makes no warranties, expressed or implied, and hereby
        disclaims and negates all other warranties, including without
        limitation, implied warranties or conditions of merchantability, fitness
        for a particular purpose, or non-infringement of intellectual property
        or other violation of rights. Further, FileFusion.fun does not warrant
        or make any representations concerning the accuracy, likely results, or
        reliability of the use of the materials on its Internet web site or
        otherwise relating to such materials or on any sites linked to this
        site.
      </p>
      <h3>Modifications</h3>
      <p>
        FileFusion.fun may revise these terms of use for its web site at any
        time without notice. By using this web site you are agreeing to be bound
        by the then current version of these Terms and Conditions of Use.
      </p>
      <h3>Governing Law</h3>
      <p>
        Any claim relating to FileFusion.fun's web site shall be governed by the
        laws of the State of Delaware without regard to its conflict of law
        provisions.
      </p>
    </div>
  );
};

export default TermsOfService;
