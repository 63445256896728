import React, { useState } from "react";
import axios from "axios";
import Loader from "../components/Loader";
import AdSenseAd from "../components/AdSenseAd";
import "../assets/styles/PdfToWord.css";
import { BASE_URL } from "../components/Globals";

function PdfToWord() {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [wordZipUrl, setWordZipUrl] = useState("");

  const onFilesChange = (e) => {
    setFiles(e.target.files);
  };

  const onFilesUpload = async (e) => {
    e.preventDefault();
    if (files.length === 0) {
      setMessage("Please select files to upload");
      return;
    }

    setLoading(true);
    setMessage("");

    const formData = new FormData();
    Array.from(files).forEach((file, index) => {
      formData.append(`files`, file); // Use `files` to match backend
    });

    try {
      const response = await axios.post(`${BASE_URL}convert/word`, formData, {
        responseType: "blob",
        timeout: 60000 * 5,
      });

      // Create a download link for the returned zip file
      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      setWordZipUrl(url);
      setMessage("Files converted successfully.");
    } catch (error) {
      console.error("There was an error uploading the files!", error);
      setMessage("There was an error uploading the files!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content-container">
      <div className="file-upload-container">
        <h2 className="title">PDF to Word Converter</h2>
        <form onSubmit={onFilesUpload} className="upload-form">
          <input
            type="file"
            multiple
            onChange={onFilesChange}
            className="file-input"
          />
          <button type="submit" disabled={loading} className="upload-button">
            Upload
          </button>
        </form>
        {loading && <Loader visible={loading} />}
        {message && <p className="message">{message}</p>}
        {wordZipUrl && (
          <a
            href={wordZipUrl}
            download="output_word_documents.zip"
            className="download-link"
          >
            Download Converted Word Documents
          </a>
        )}
        <AdSenseAd />
      </div>
      <div className="information-section">
        <h3>Why Convert PDF to Word?</h3>
        <p>
          Converting PDFs to Word documents allows for easy editing, sharing,
          and formatting. It's especially useful for making quick changes or
          collaborating with others who may not have access to PDF editing
          software.
        </p>
      </div>
      <div className="faq-section">
        <h3>Frequently Asked Questions</h3>
        <div className="faq-item">
          <h4>How do I convert a PDF to a Word document?</h4>
          <p>
            Simply upload your PDF files using the form above and click
            "Upload". Your files will be converted to Word documents, and you'll
            receive a download link.
          </p>
        </div>
        <div className="faq-item">
          <h4>Is there a file size limit?</h4>
          <p>
            Currently, we support files up to 20MB. If you have larger files,
            consider splitting them before uploading.
          </p>
        </div>
        <div className="faq-item">
          <h4>Are my files secure?</h4>
          <p>
            Yes, we use secure connections to ensure your files are uploaded and
            processed safely. Files are deleted from our servers after
            processing.
          </p>
        </div>
      </div>
      <div className="testimonial-section">
        <h3>User Testimonials</h3>
        <div className="testimonial">
          <p>
            "This tool is incredibly easy to use and has saved me so much time!"
            - Alex J.
          </p>
        </div>
        <div className="testimonial">
          <p>
            "Highly recommend for anyone needing to convert PDFs to Word
            documents quickly." - Maria K.
          </p>
        </div>
      </div>
      <AdSenseAd />
    </div>
  );
}

export default PdfToWord;
