import React from "react";
import "../assets/styles/AdSense.css";
const AdSenseAd = () => {
  return (
    <div className="ad-container">
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-XXXXX"
        data-ad-slot="XXXXX"
        data-ad-format="auto"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    </div>
  );
};

export default AdSenseAd;
