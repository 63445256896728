import React, { useState } from "react";
import axios from "axios";
import Loader from "../components/Loader";
import AdSenseAd from "../components/AdSenseAd";
import "../assets/styles/TextToHandwriting.css";
import { BASE_URL } from "../components/Globals";

const TextToHandwriting = () => {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [handwritingUrl, setHandwritingUrl] = useState("");

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const convertToHandwriting = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}convert/handwriting`, {
        text,
      });
      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      setHandwritingUrl(url);
      setMessage("Text converted to handwriting successfully!");
    } catch (error) {
      console.error("Error converting text to handwriting:", error);
      setMessage("Error converting text to handwriting. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    window.open(handwritingUrl, "_blank");
  };

  return (
    <div className="content-container">
      <div className="text-editor-container">
        <h2 className="title">Text to Handwriting Converter</h2>
        <textarea
          value={text}
          onChange={handleTextChange}
          className="text-area"
          placeholder="Enter your text here..."
          rows={10} // Increase the size of the textarea
        />
        <button
          className="convert-button"
          onClick={convertToHandwriting}
          disabled={loading}
        >
          {loading ? <Loader visible={loading} /> : "Convert to Handwriting"}
        </button>
        {handwritingUrl && (
          <button className="download-button" onClick={handleDownload}>
            Download Handwriting
          </button>
        )}
        {message && <p className="message">{message}</p>}
      </div>
      <div className="information-section">
        <h3>Why Convert Text to Handwriting?</h3>
        <p>
          Converting text to handwriting adds a personal touch and can be useful
          for creating handwritten notes, letters, or other personalized
          content.
        </p>
      </div>
      <div className="faq-section">
        <h3>Frequently Asked Questions</h3>
        <div className="faq-item">
          <h4>Can I convert long text to handwriting?</h4>
          <p>
            Yes, you can convert any length of text to handwriting using our
            tool.
          </p>
        </div>
        <div className="faq-item">
          <h4>Is the handwriting customizable?</h4>
          <p>
            No, the handwriting style is predefined and cannot be customized.
          </p>
        </div>
        <div className="faq-item">
          <h4>Can I download the handwriting as an image?</h4>
          <p>
            Yes, once the text is converted, you can download the handwriting as
            a pdf file.
          </p>
        </div>
      </div>
      <div className="testimonial-section">
        <h3>User Testimonials</h3>
        <div className="testimonial">
          <p>
            "This tool is amazing! It saved me so much time and the handwritten
            output looks fantastic." - John D.
          </p>
        </div>
        <div className="testimonial">
          <p>
            "I love how easy it is to convert text to handwriting with this
            tool. Highly recommended!" - Sarah L.
          </p>
        </div>
      </div>
      <AdSenseAd />
    </div>
  );
};

export default TextToHandwriting;
