import React, { useState } from "react";
import axios from "axios";
import Loader from "../components/Loader";
import AdSenseAd from "../components/AdSenseAd";
import "../assets/styles/PdfToImg.css";
import { BASE_URL } from "../components/Globals";

function PdfToImg() {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const onFilesChange = (e) => {
    setFiles(e.target.files);
  };

  const onFilesUpload = async (e) => {
    e.preventDefault();
    if (files.length === 0) {
      setMessage("Please select files to upload");
      return;
    }

    const formData = new FormData();
    Array.from(files).forEach((file, index) => {
      formData.append(`file${index}`, file);
    });
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}convert/images`, formData, {
        responseType: "blob",
        timeout: 60000 * 5,
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadUrl(url);
      setMessage(
        "Files converted successfully. Click the link below to download."
      );
    } catch (error) {
      console.error("There was an error uploading the files!", error);
      setMessage("There was an error uploading the files!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content-container">
      <div className="file-upload-container">
        <h2 className="title">PDF to Images Converter</h2>
        <form onSubmit={onFilesUpload} className="upload-form">
          <input
            type="file"
            multiple
            onChange={onFilesChange}
            className="file-input"
          />
          <button type="submit" disabled={loading} className="upload-button">
            Upload
          </button>
        </form>
        {loading && <Loader visible={loading} />}
        {message && <p className="message">{message}</p>}
        {downloadUrl && (
          <a
            href={downloadUrl}
            download="output_images.zip"
            className="download-link"
          >
            Download Converted Images
          </a>
        )}
        <AdSenseAd />
      </div>
      <div className="information-section">
        <h3>Why Convert PDF to Images?</h3>
        <p>
          Converting PDFs to images can be useful for a variety of reasons. It
          allows for easy sharing of documents without requiring a PDF reader,
          simplifies embedding documents into web pages, and helps in converting
          sections of PDFs into editable formats.
        </p>
      </div>
      <div className="faq-section">
        <h3>Frequently Asked Questions</h3>
        <div className="faq-item">
          <h4>How do I convert a PDF to an image?</h4>
          <p>
            Simply upload your PDF files using the form above and click
            "Upload". Your files will be converted to images, and you'll receive
            a download link.
          </p>
        </div>
        <div className="faq-item">
          <h4>Is there a file size limit?</h4>
          <p>
            Currently, we support files up to 20MB. If you have larger files,
            consider splitting them before uploading.
          </p>
        </div>
        <div className="faq-item">
          <h4>Are my files secure?</h4>
          <p>
            Yes, we use secure connections to ensure your files are uploaded and
            processed safely. Files are deleted from our servers after
            processing.
          </p>
        </div>
      </div>
      <div className="testimonial-section">
        <h3>User Testimonials</h3>
        <div className="testimonial">
          <p>
            "This tool is incredibly easy to use and has saved me so much time!"
            - Alex J.
          </p>
        </div>
        <div className="testimonial">
          <p>
            "Highly recommend for anyone needing to convert PDFs to images
            quickly." - Maria K.
          </p>
        </div>
      </div>
      <AdSenseAd />
    </div>
  );
}

export default PdfToImg;
