import React from "react";
import "../assets/styles/FAQ.css";

const FAQ = () => {
  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-item">
        <h2>How do I convert a PDF to an image?</h2>
        <p>
          Simply upload your PDF files using our PDF to Image Converter tool and
          click "Upload". Your files will be converted to images, and you'll
          receive a download link.
        </p>
      </div>
      <div className="faq-item">
        <h2>Is there a file size limit?</h2>
        <p>
          Currently, we support files up to 20MB. If you have larger files,
          consider splitting them before uploading.
        </p>
      </div>
      <div className="faq-item">
        <h2>Are my files secure?</h2>
        <p>
          Yes, we use secure connections to ensure your files are uploaded and
          processed safely. Files are deleted from our servers after processing.
        </p>
      </div>
      <div className="faq-item">
        <h2>How long does the conversion process take?</h2>
        <p>
          The conversion process usually takes a few seconds to a couple of
          minutes, depending on the size and complexity of your files.
        </p>
      </div>
      <div className="faq-item">
        <h2>Do you support other file formats?</h2>
        <p>
          Yes, we are continuously working to support more file formats. Please
          check our website for the latest updates.
        </p>
      </div>
    </div>
  );
};

export default FAQ;
